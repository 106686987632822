$themeAccentColor1: #814495;
$buttonColor1: #eb4d4b;
$buttonAccentColor1: #ff7979;

// -------------------------- NavBar ----------------------------//
.navbar {
  & { background: #1c2238; color: #fff; display: flex; align-items: center; justify-content: space-between; height: 65px; }

  .company-logo {
    & { cursor: pointer; width: 65px; height: 65px; display: flex; align-items: center; justify-content: center; padding: 0 0 0 15px; background: #151929; }
    img { height: 50px; width: 50px; }
  }

  .user-nav {
    & { display: flex; align-items: center; justify-content: center; gap: 15px; padding: 0 15px; }
    .user-avatar {
      & { cursor: pointer; }
      & { height: 30px; width: 30px; background: #fff; border-radius: 5px; }
    }
    .user-name {
      & { display: flex; flex-direction: column; align-items: center; max-width: 150px; }
      .username { white-space: pre; overflow: hidden; text-overflow: ellipsis; width: 100%; }
      .user-email { font-size: 10px; opacity: 0.7;  white-space: pre; overflow: hidden; text-overflow: ellipsis; width: 100%; }
    }
    .nav-dropdown {
      & { cursor: pointer; height: 30px; width: 30px; }
    .nav-dropdown-icon { height: 30px; width: 30px; }

    }
  }
}


// ------------------- all - main - container --------------------//
.main-container {
  & { display: flex; height: 100%; background: #f2f3f7; color: #000; width: 100%;}
}

// -------------------------- NavBar ----------------------------//
.main {
  & { display: flex; height: 100%; flex: 1; }
}


// -------------------------- SideBar ----------------------------//
// .sidebar {
//   & { background: #1c2238; color: #fff; width: 200px; min-width: 200px; height: 100%;  display: flex; flex-direction: column; align-items: center; justify-content: space-between; transition: width 200ms ease; }
//   .item-icon { height: 20px; width: 20px; }
//   .manage-container {
//     & { width: 100%; padding-left: 4%; padding-top: 10px; }
//     .manage-heading {
//       & { height: 40px; width: 100%; margin: 15px 0; display: flex; align-items: center; justify-content: space-between; font-weight: 600; font-size: 20px; }
//       .back-icon { cursor: pointer; height: 35px; width: 35px; margin: 0 15px; }
//     }
//   }
//   .build-container {
//     & { width: 100%; padding-left: 4%; margin-bottom: auto; }
//     .build-heading {
//       & { height: 40px; width: 100%;  margin: 10px 0; font-weight: 600; font-size: 20px; }
//     }
//     .new-coupon-btn {
//       & { width: 155px;  min-width: 30px !important; height: 35px; font-weight: 700; margin: 0 auto; background: #e84118; color: #fff; border-radius: 20px; display: flex; align-items: center; gap:  7px; }
//     }
//   }
//   .admin-container {
//     & { align-self: flex-end; width: 100%; padding-left: 4%; }
//   }
// }

.menu {

  & { background: #e9eaee;color: #fff; width: 200px; min-width: 200px; height: 100%; transition: width 200ms ease;}
  .company-logo { text-align: center; margin-top: 40px; display: flex; align-items: center; justify-content: center; }
  .company-logo img { max-width: 70%; }

  nav { margin: 20px 12%; }

  nav a {display: flex; width: 100%; margin: 0 auto;color: #57606f;text-decoration: none; font-size: 1em; font-weight: 400; padding: 10px 5px; background: none;  gap: 16px;}
  nav .MuiSvgIcon-root { margin-top: -2px; margin-right: 10px; }

  nav a:first-of-type {border-top: none;}
  nav a:last-of-type {border-bottom: none;}
  nav a:hover {color: #57606f;}

  .menulogo {height: 32px;margin: 6px 0 2px -20px;}

}

.menu-universe{

 & { background: #fff;color: #fff; width: 250px; min-width: 250px; height: 100%; transition: width 200ms ease; display: flex; flex-direction: row;}
  .company-logo-universe { text-align: center; margin-bottom: 30px; }
  .company-content{ margin: 32px 25px 0px 25px;}
 .company-content nav{ display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 20px;}
 .company-content nav a{ height: 20px;}
    .company-logo-universe img { height: auto;}
}
.dualbar-content { &{background-color: #E9EAEE ; width: -webkit-fill-available;}



  nav { margin: 20px 12%; }

  nav a {display: flex; width: 100%; margin: 0 auto;color:#000 ;text-decoration: none; font-size: 17px; font-weight: 600; padding: 10px 5px; background: none;  gap: 16px;}
  nav .MuiSvgIcon-root { margin-top: -2px; margin-right: 10px; }

  nav a:first-of-type {border-top: none;}
  nav a:last-of-type {border-bottom: none;}
  nav a:hover {color: #57606f;}

  .company-logo {margin-top: 25px;padding-left: 27px;}
}
