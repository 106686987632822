
.universe-page {

 .main{display: flex;  flex-direction: row;}
    .universe-section .section-one .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected { color: #CD4A4A; }
    .universe-section .section-one .css-1aquho2-MuiTabs-indicator { background-color: #CD4A4A;}
        .universe-section .section-one .heading { margin-left: 200px;}
        .universe-section .section-one .contentmob { margin-left: 160px;}
        .universe-section .section-one .contentdesktop { margin-left: 30px;}
 
    .section-two{
        &{ width: 360px ; margin-top: 20px; background-color: #FFFFFF; border-radius: 8px; position: absolute; right: 25px;}
    h3{ text-align: center; margin-top: -5px; margin-bottom: 8px; font-size: 20px; color: #000000;}
    .content{ padding: 20px;}

    .btn-save{ width: 100%; height: 6vh; color: #FFFFFF; background-color: #CD4A4A; border-radius: 5px; border: none; font-weight: 500; font-size: 20px; margin-top: 10px;}
    .color-box{
        &{ display: flex; flex-direction: column;}
        .form-label{font-weight: 500;font-size: 13px;}
    input{ padding: 0; margin: 0; width: 70%; box-shadow: 2px 2px 1px #0000001A;}
    input:hover{ border: none;}

    input[type="color"]::-webkit-color-swatch-wrapper {padding: 0; border-radius: 0;  border: none;background: #FFFFFF 0% 0% no-repeat padding-box;}}
    }
      

      @media (max-width: 900px) {

     .main{ display: block;}
        
      }
}