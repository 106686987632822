@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');
$mulish : 'Mulish', sans-serif;

.drawer-container {
  & { height: 100%;  }
}

.drawer {
  & { background-color: #F6F7FA; width: 85vw; height: 100vh; }
  .tool-button { height: 24px; width: 24px; margin-right: 10px; cursor: pointer; color: #007C89; }
  .active { border: 1px solid blue; }
  .disable { border: none; }
    header {
      & { border-bottom: 1px solid #ccc; padding: 0 1rem; color: #000000; width: auto; max-width: 100%; height: 60px;  display: flex; align-items: center; }
      h1 {
        & { margin: 0; font-size: 24px; color: #000000; align-items: center; display: flex; }
        .back-button { height: 24px; width: 24px; margin-right: 10px; }
      }
    }
    .MuiTabs-flexContainer { background: #ffffff; }
    section { 
      & { overflow-y: scroll; height: calc(100% - 60px); width: 100%; padding-top: 1rem; }
    .double-column-form { 
      & { display: flex; align-items: center; }
      .MuiInputBase-fullWidth { width: 98%; }
      #file-type { top: -5px; left: 15px; }
    }
    .margin-top { margin-top: 15px; }
    .aligned-right { justify-content: flex-end; margin-right: 1%; }
    }
}