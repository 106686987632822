/* You can add global styles to this file, and also import other style files */
@use '../Layout/Layout.scss';
@use '../pages/Networks/Networks.scss';
@use '../pages/Users/Users.scss';
@use '../pages/Topics/Topics.scss';
@use '../pages/Tickets/Tickets.scss';
@use '../pages/Login/Login.scss';
@use '../pages/Universe/Universe.scss';
@use '../pages/Universe/UniverseNue.scss';
@use '../pages/Universe/UniverseNueOverView.scss';
@use './Drawer.scss';
@use './Modal.scss';


// fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');
$mainFont : 'Nunito', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');
$mulish : 'Mulish', sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,900&display=swap');
$roboto : 'Roboto', sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Sofia&display=swap');
$sofia : 'Sofia', cursive;

@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap');
$syne: 'Syne', sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap');
$courier : 'Courier Prime', monospace;

@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,600;0,700;0,800;1,500;1,600;1,700&display=swap');
$garamond : 'EB Garamond', serif;



$themeColor1: #00A4CC; 
$themeAccentColor1: #814495;
$buttonColor1: #eb4d4b;
$buttonAccentColor1: #ff7979;


/*
  1. Use a more-intuitive box-sizing model.
*/
*, *::before, *::after {
  box-sizing: border-box;
  // outline: 1px solid #ff0000;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
}
/*
  3. Allow percentage-based heights in the application
*/
html, body {
  height: 100%;
  background: #fff;
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  font-family: $mainFont;
}
/*
  6. Improve media defaults
*/
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/
input, button, textarea, select {
  font: inherit;
}
/*
  8. Avoid text overflows
*/
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
/*
  9. Create a root stacking context
*/
#root, #__next {
  isolation: isolate;
}


//this is the global container 
.app-container {
  & { height: 100vh; width: 100vw; }
}


.link {
  & { font-weight: 600; font-size: 16px; color: #111; text-decoration: none; border-bottom: 2px solid transparent; transition: border-bottom 250ms ease;}
  &:visited { color: #323232; }
  &:hover { border-bottom: 2px solid #323232; }
}

.df-center {
  & { display: flex; align-items: center; justify-content: center; }
}

.w-100 {
  & { width: 100%; }
}

.mtb-1rem {
  & { margin: 1rem 0; }
}

.mtb-10px {
  & { margin: 10px 0; }
}

.btn-primary {
  & { display: flex; align-items: center; justify-content: center; gap: 10px; min-width: 80px; height: 37px; font-family: inherit; font-size: 16px; font-weight: 600; background: $themeColor1; border: 2px solid transparent; color: #fff; box-shadow: 0 0 5px #bdbdbd79; border-radius: 3px; transition: 250ms ease-in-out; cursor: pointer; }
  &:hover { filter: drop-shadow(0 0 5px #00000030) brightness(105%) saturate(105%); }
  &:disabled {  background: #afafaf !important; }
} 

.btn-primary-border {
  & { display: flex; align-items: center; justify-content: center; gap: 10px; min-width: 80px; height: 37px; font-family: inherit; font-size: 16px; font-weight: 600; background: #ffffff; border: 2px solid $themeColor1; color: $themeColor1; box-shadow: 0 0 5px #bdbdbd79; border-radius: 3px; transition: 250ms ease-in-out; cursor: pointer; }
  &:hover { filter: drop-shadow(0 0 5px #00000030) brightness(105%) saturate(105%); }
} 

.btn-white {
  & { display: flex; align-items: center; justify-content: center; gap: 10px; min-width: 80px; height: 37px; font-family: inherit; font-size: 16px; font-weight: 600; background: #ffffff; color: #000000; border: 2px solid transparent; box-shadow: 0 0 10px #bdbdbd79; border-radius: 3px; transition: 250ms ease-in-out; cursor: pointer; }
  &:hover { filter: drop-shadow(0 0 5px #00000030) brightness(105%) saturate(105%); }
} 

.btn-white-border {
  & { display: flex; align-items: center; justify-content: center; gap: 10px; min-width: 80px; height: 37px; font-family: inherit; font-size: 16px; font-weight: 600; background: #ffffff; color: #000000; border: 2px solid #000000; box-shadow: 0 0 10px #bdbdbd79; border-radius: 3px; transition: 250ms ease-in-out; cursor: pointer; }
  &:hover { filter: drop-shadow(0 0 5px #00000030) brightness(105%) saturate(105%); }
} 

.btn-red-border {
  & { display: flex; align-items: center; justify-content: center; gap: 10px; min-width: 80px; height: 37px; font-family: inherit; font-size: 16px; font-weight: 600; background: #ffffff; color: #FF0000; border: 2px solid #FF0000; box-shadow: 0 0 10px #bdbdbd79; border-radius: 3px; transition: 250ms ease-in-out; cursor: pointer; }
  &:hover { filter: drop-shadow(0 0 5px #00000030) brightness(105%) saturate(105%); }
} 

.btn-green-border {
  & { display: flex; align-items: center; justify-content: center; gap: 10px; min-width: 80px; height: 37px; font-family: inherit; font-size: 16px; font-weight: 600; background: #ffffff; color: #00b500; border: 2px solid #00b500; box-shadow: 0 0 10px #bdbdbd79; border-radius: 3px; transition: 250ms ease-in-out; cursor: pointer; }
  &:hover { filter: drop-shadow(0 0 5px #00000030) brightness(105%) saturate(105%); }
} 

.btn-black {
  & { display: flex; align-items: center; justify-content: center; gap: 10px; min-width: 80px; height: 37px; font-family: inherit; font-size: 16px; font-weight: 600; background: #111; color: #fff; border: 2px solid transparent; box-shadow: 0 0 10px #bdbdbd79; border-radius: 3px; transition: 250ms ease-in-out; cursor: pointer; }
  &:hover { background: #1b1b1b; }
} 


.text-input {
  & { height: 37px; width: 100%; }
  .form-label {
    & { text-transform: capitalize; margin-bottom: 0.3rem; font-weight: 600; }
  }
  .form-input {
    & { height: 37px;  border-radius: 3px; width: 100%; display: flex; align-items: center; justify-content: center; }
    input {
      & {  font-weight: 500; flex: 1; border-radius: 3px; margin-right: auto; border: 2px solid #CACACA; height: 37px; padding-left: .5rem; background: #ffffff; }
      &:hover { border: 2px solid #b5b5b5; }
      &:focus { outline: none; border: 2px solid #8a8a8a; }
    }
    img {margin: 0 .5rem;}
  }
  .form-error {
    & { color: #D63F15; font-size: 12px; font-weight: 600; }
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
}

::-webkit-scrollbar-thumb {
  background-color: #939393;
  outline: 1px solid #ffffff;
  border-radius: 1px;
}

