
.universe-page {
  & { height: 100%; width: 100%; font-family: "Lato"; }

 .universe-header {
    & { display: flex; align-items: center; justify-content: space-between; padding: 0 1rem; height: 60px; }
    h1{font-family: "Lato";}
    .action-bar { 
      & {display: flex; gap: 1rem;} 
      .btn-universe{ background-color:#CD4A4A;  font-family: "Lato"; padding: 16px;}
      
    }
  }
   .universe-section {
    & { height: calc(100% - 60px); padding: 1rem; max-height: 95%; overflow: scroll; scroll-behavior: smooth;}

    .universe-container{
      &{display: grid;
        grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
        grid-gap: 10px;
        }
      .universe-content{height: 335px; background-color: #fff; box-sizing: border-box; width: 333px; border-radius: 10px; }
     
       .universe-main{
       &{display: flex;flex-direction: column; padding: 10px; cursor: pointer;}
       h4{margin-top: 10px;}
       }
       .middle-content{
        display: flex; justify-content: space-between; align-items: center; margin-top: 20px;
       }
       .content-item{
     &{display: flex; align-items: center;}

        p{ white-space: nowrap; font-size: 14px;}
        h1{white-space: nowrap; font-size: 14px;}
       
       }
        .bottom-content{
          &{display: flex; align-items: center; justify-content: space-evenly;}
        p{white-space: nowrap; font-size: 14px; padding: 0px 5px 0px 5px;}

        }
    }
   }

      .universe-section::-webkit-scrollbar {
       display: none;
     }
 
}


