.upload-image-modal-container {
  & { height: 80vh; width: 60vw; display: flex; flex-direction: column; align-items: center; justify-content: space-between; }

  .title { 
    & { height: 50px; width: 100%; display: flex; align-items: center; justify-content: space-between; padding: 0 15px; }
    h2 { margin: 0; color: #AA7B59; }
    .MuiSvgIcon-root { color: #AA7B59; cursor: pointer;}
  }

  .crop {
    & {  height: calc(100% - 130px); padding: 10px 0; position: relative; width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: space-evenly; }
    .d-n-d { 
      & { height: 80%; width: 90%; border: 2px dashed #929292; border-radius: 10px; display: flex; align-items: center; justify-content: center;}
    }
  }

  .crop-btns {
    & { position: relative; height: 80px; width: 100%; display: flex; align-items: center; justify-content: space-between; }
    .tips {
      & { position: absolute; bottom: -10px; left: 0px; display: flex; align-items: center; justify-content: flex-start; }
      &>span {
        & { color: #AA7B59; font-size: 14px; }
      }
    }
  }
}


.select-item-modal {
  & { max-height: 600px; width: 575px; min-width: 550px; max-width: 550px; }
  section {
    & { display: flex; flex-direction: column; height: 90%; }

    .save-changes-btn { 
      & { margin-top: auto; display: flex; gap: 16px; }
      .close-btn { color: #1a66ff; background: #fff; border: 2px solid #1a66ff; }
    }

    .search-item-container {
      & { position: relative; height: 40px; width: 100%; padding: 0 16px; display: flex; align-items: center; justify-content: space-between; border: 1px solid #aaa; border-radius: 5px; }
      input { 
        & { width: calc(100% - 40px); border: none; font-size: 18px; }
        &:focus { outline: none; }
      }
      .drop-down-btn { cursor: pointer; height: 26px; width: 26px; border-radius: 100%; background: #eee; transition: transform 250ms ease; }
    }
    .items-menu {
      & { width: 100%;  max-height: 275px; margin-top: 8px; border: 1px solid #aaa; border-radius: 5px; overflow-y: scroll; overflow-x: hidden; }
      ul {
        & { height: 100%; width: 100%; list-style: none; padding: 0 5px; margin: 5px 0; }
        li { 
          & { height: 40px; width: 100%; font-size: 18px; padding-left: 12px; border-radius: 5px; display: flex; align-items: center; justify-content: flex-start; cursor: pointer; }
          &:hover { background: #eee; }
        }
        .add-new-item {
          & { border: 1px solid #aaa; margin-top: 5px; }
          &:hover { background: #d4e3ff; }
          .item-text { color: #1a66ff; font-weight: 600; }
        }
      }
    }
    .author-list {
      & { margin-top: 16px; display: flex; flex-wrap: wrap; }
    }
  }
}

.enter-time-modal{
  &{ display: flex; flex-direction: column; gap: 20px; width: 30vw; }
  .enter-time-heading{
    &{ display: flex; justify-content: space-between; }
    svg{ cursor: pointer; }
  }
  .enter-time-input-container{
    &{ display: flex; flex-direction: column; gap: 5px; }
    input{
      &{ width: 100%; } 
    }
    input::placeholder{
      &{ font-size: 14px; }
    }
  }
  .save-changes-btn{
    &{ display: flex; justify-content: space-between; gap: 20px; }
    button{
      &{ width: 100%; }
    }
  }
}

.enter-ingredient-modal{
  &{ display: flex; flex-direction: column; gap: 20px; width: 30vw; }
  .enter-ingredient-heading{
    &{ display: flex; justify-content: space-between; }
    svg{ cursor: pointer; }
  }
  .enter-ingredient-input-container{
    &{ display: flex; flex-direction: column; gap: 5px; }
    input{
      &{ width: 100%; border-radius: 5px; border: 2px solid #e4e4e4; padding: 5px 10px; } 
    }
    input::placeholder{
      &{ font-size: 14px; }
    }
  }
  .link-hh-product{
    &{ display: flex; flex-direction: column; gap: 5px; }
    .select-tag{
      &{ width: 100%; font-size: 14px; padding: 5px 0; outline: none; } 
    }
    .option-container{
      &{ display: flex; justify-content: space-between; align-items: center; }
      .select-icon{
        svg{ color: #c7c7c7; }
      }
    }
  }
  .save-changes-btn{
    &{ display: flex; justify-content: space-between; gap: 20px; }
    button{
      &{ width: 100%; }
    }
  }
}