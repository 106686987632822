
.tickets-page {
  & { height: 100%; width: 100%; }

  .template-header {
    & { display: flex; align-items: center; justify-content: space-between; padding: 0 1rem; height: 60px; }
    .action-bar { 
      & {display: flex; gap: 1rem;} 
      .filters {
        & { display: flex; align-items: center; justify-content: center; gap: 1rem; }
      }
    }
  }
  .template-section {
    & { height: calc(100% - 60px); padding: 1rem; }

    //main grid/table
    .tickets {
      & { position: relative; width: 100%; height: 100%; display: flex; align-items: flex-start; align-content: flex-start; flex-wrap: wrap; gap: 15px; overflow-y: scroll; border-radius: 10px; box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;}
      
      .tickets-table { height: 100%; overflow-x: visible !important; }

      .user-image, .user-initial {width: 40px; height: 40px; object-fit: cover; border-radius: 10px; display: flex; align-items: center; justify-content: center;}
      .avatar-text, .initial {width: 40px; height: 40px; object-fit: cover; border-radius: 50%; display: flex; align-items: center; justify-content: center;}

      .status-active {
        & { position: relative; display: flex; align-items: center; justify-content: center; gap: 10px; border-radius: 5px; height: 25px; background: #dff0de; color: #329c3a; }
        &::before { content: ""; position: absolute; left: 8px; width: 7px; height: 7px; border-radius: 50%; top: calc(50% - 4px); background: #329c3a; }
      }
      .status-invite {
        & { position: relative; display: flex; align-items: center; justify-content: center; gap: 10px; border-radius: 5px; height: 25px; background: #fff2d7; color: #f9b104; }
        &::before { content: ""; position: absolute; left: 8px; width: 7px; height: 7px; border-radius: 50%; top: calc(50% - 4px); background: #f9b104; }
      }
      .status-pending {
        & { position: relative; display: flex; align-items: center; justify-content: center; gap: 10px; border-radius: 5px; height: 25px; background: #d4e7ff; color: #0472f9; }
        &::before { content: ""; position: absolute; left: 8px; width: 7px; height: 7px; border-radius: 50%; top: calc(50% - 4px); background: #0472f9; }
      }
      .status-suspended {
        & { position: relative; display: flex; align-items: center; justify-content: center; gap: 10px; border-radius: 5px; height: 25px; background: #ffced8; color: #dc143c; }
        &::before { content: ""; position: absolute; left: 8px; width: 7px; height: 7px; border-radius: 50%; top: calc(50% - 4px); background: #dc143c; }
      }

      
  .table{

    .table-head { background-color: #F0F1F3;

      .head-row{
        .head-cell{ color: #9B9B9B;} 
      }
    }

    .table-body {
      .body-row { background: #FFFFFF; border-bottom: 1px solid #CACACA !important;
        .action { display: flex; justify-content: center; align-items: center; border-bottom: none !important; }
        .body-cell {
          .popup-menu { background-color: #007C89 !important; display: flex; }
          .heading { font-weight: bold; font-size: 14px; color: #000000; }
          p { font-size: 14px; color:#9B9B9B; }
          .type { margin: 10px; padding: 10px; align-items: center; display: flex; justify-content: center; gap: 3px; background: #D1ECEC; color: #1BA0A2; border-radius: 5px; }
          .status { border-radius: 20px; padding: 5px; display: flex; align-items: center; justify-content: center; text-transform: capitalize; font-size: 12px; color: white; font-size: 14px; }
          .open { background-color: red; }
          .closed { background-color: #C6C6C6; }
          .network { color: #1BA0A2; background: #D1ECEC; }
        }
      }
    }
  }

    }
  }
}
