
.networks-page {
  & { height: 100%; width: 100%; }

  .template-header {
    & { display: flex; align-items: center; justify-content: space-between; padding: 0 1rem; height: 60px; }
    .action-bar { & {display: flex; gap: 1rem;}
    .header-cta { display: block; cursor: pointer; background: #007C89; color: #fff; padding: 6px 14px; border-radius: 3px; text-transform: uppercase; font-weight: 800; font-size: 14px; }
    .search-bar { 
      & { width: 250px; right: 40px; height: 31px;}
      input[type="search"].search-field::-webkit-search-cancel-button { -webkit-appearance: none; }
      .search-field { background-color: #FBF9F3; color: #979797; padding: 10px; font-size: 16px; border: 1px solid #CBCBCB; border-radius: 3px; margin: 0 0 15px 0; width: 250px; }
      .clear-search-icon { position: absolute; right: 5px; top: 5px; cursor: pointer; color: #777777; height: 24px; width: 24px; font-size: 24px; }
    }
  }
  }
  .template-section {
    & { height: calc(100% - 60px); padding: 1rem; }

    //main grid/table
    .networks {
      & { position: relative; width: 100%; height: 100%; display: flex; align-items: flex-start; align-content: flex-start; flex-wrap: wrap; gap: 15px; overflow-y: scroll; border-radius: 10px; box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;}

      .network-table { height: 100%; overflow-x: visible !important; }
      
      .network-image, .network-initial {width: 40px; height: 40px; object-fit: cover; border-radius: 10px; display: flex; align-items: center; justify-content: center;}
      .avatar-text, .initial {width: 40px; height: 40px; object-fit: cover; border-radius: 50%; display: flex; align-items: center; justify-content: center;}

      .status-active {
        & { position: relative; display: flex; align-items: center; justify-content: center; gap: 10px; border-radius: 5px; height: 25px; background: #dff0de; color: #329c3a; }
        &::before { content: ""; position: absolute; left: 8px; width: 7px; height: 7px; border-radius: 50%; top: calc(50% - 4px); background: #329c3a; }
      }
      .status-invite {
        & { position: relative; display: flex; align-items: center; justify-content: center; gap: 10px; border-radius: 5px; height: 25px; background: #fff2d7; color: #f9b104; }
        &::before { content: ""; position: absolute; left: 8px; width: 7px; height: 7px; border-radius: 50%; top: calc(50% - 4px); background: #f9b104; }
      }
      .status-pending {
        & { position: relative; display: flex; align-items: center; justify-content: center; gap: 10px; border-radius: 5px; height: 25px; background: #d4e7ff; color: #0472f9; }
        &::before { content: ""; position: absolute; left: 8px; width: 7px; height: 7px; border-radius: 50%; top: calc(50% - 4px); background: #0472f9; }
      }
      .status-suspended {
        & { position: relative; display: flex; align-items: center; justify-content: center; gap: 10px; border-radius: 5px; height: 25px; background: #ffced8; color: #dc143c; }
        &::before { content: ""; position: absolute; left: 8px; width: 7px; height: 7px; border-radius: 50%; top: calc(50% - 4px); background: #dc143c; }
      }

    }
  }
}
