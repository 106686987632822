.overview-universe{
    .universe-overview{
    &{height: 185px;    width: 30%;    margin-top: 20px;    background-color: #FFFFFF;    border-radius: 10px;    padding: 20px;
    }
    h1{text-align: center; font-size: xxx-large;}
    h3{text-align: center; margin-bottom: 10px; height: 30px; font-size: 15px; line-height: normal;}
   .overview-headcontent{ display: flex; justify-content: space-evenly; align-items: center;}
  }
    .main-head{display: flex; gap: 15px;}
    .second-head{
    &{display: flex; gap: 15px;}
    .universe-overview-second{height: 180px;    width: 30%;    margin-top: 20px;    background-color: #FFFFFF;    border-radius: 10px;    padding: 20px;}

   h1{text-align: center; font-size: xxx-large;}
    h3{text-align: center; height: 30px; font-size: 15px; margin-bottom: 10px; line-height: normal;}
   .overview-headcontent-second{ display: flex; justify-content: space-evenly; align-items: center;}

    }
  .map-section{
    &{height: 670px;    width:100%; margin-top: 20px;  background-color: #FFFFFF;    border-radius: 10px;    padding: 20px; display: flex; flex-direction: column; gap: 30px;}
     h2{text-align: center; font-size: 17px; font-family: 'Nunito'; font-weight: bold;}
     img{height: 500px;}
     .map-about{
        &{display: flex; justify-content: space-evenly; align-items: center;}
        .map-about-container{display: flex; gap: 10px;}
        .map-color{ height: 12px; width: 12px; border-radius: 50%; margin-top: 6px; }
        .map-about-content{display: flex; flex-direction: column; }
        h3{font-weight: bold;}
        p{font-weight: 600;}
     }
  }


  .statistic-section{
    &{display: flex;flex-direction: row;gap: 15px; margin-top: 20px;}

.section-statistic-one{height: 400px;    width:50%;  background-color: #FFFFFF; border-radius: 10px;    }
.section-statistic-two{height: 400px;    width:50%;   background-color: #FFFFFF; border-radius: 10px;  }


  }


}